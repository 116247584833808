<template>
  <div class="v_task_issue_dian_fu_step_three">
    <el-form :model="form" :rules="rules" ref="ruleForm">
       <div class="s-task-issue-step-item s-task-issue-step-one-item-first">
        <el-row>
          <el-col :span="22">
             <el-table :data="tableData" border style="width: 100%" :span-method="arraySpanMethod">
              <el-table-column label="费用明细">
                <template slot-scope="scope">
                  {{scope.row.title}}:
                  <span class="g-padleft5">
                    {{scope.row.price}} 元 / 单
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="小计"> 
                <template slot-scope="scope">
                  <span class="g-fsize18">
                    {{scope.row.value}} 元
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="" label="数量">
                <template slot-scope="scope">
                  <span class="g-fsize18">
                   {{scope.row.number}} 单
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="合计">
                <template slot-scope="scope">
                  <span class="g-red g-fsize22">
                    {{scope.row.total}} 元
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>

  

        <!-- 活动个数 -->
        <!-- <el-row :gutter="50" class="s-task_issue_dian_fu-item-active-num g-martop30">
           <el-col :span="24">
            <el-form-item label="主宝贝浏览：" label-width="180px" class="g-flex-align-center">
              <p class="g-fsize22 g-grey">{{form.buy_num}}<span class="g-fsize18">个</span></p>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 支付金额 -->
        <el-row :gutter="50" class="s-task_issue_dian_fu-item-active-num">
           <el-col :span="24">
            <el-form-item label="支付金额：" label-width="180px" class="g-flex-align-center">
              <p class="g-fsize30 g-tips-color">¥{{Math.formatFloat((this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney) * this.totalTaskNum, 2)}}</p>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 支付方式 -->
        <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="支付金额：" label-width="180px">
               <el-radio-group v-model="formData.payType">
                <el-radio :label="1">余额支付</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
       </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    props: {
      BenJinMoney: {
        type: Number,
        default() {
          return 0
        }
      },
      servicePrice: {
        type: Number,
        default() {
          return 0
        }
      },
      BaseMoney: {
        type: Number,
        default() {
          return 0
        }
      },
      ZengZhiMoney: {
        type: Number,
        default() {
          return 0
        }
      },
      totalTaskNum: {
        type: Number,
        default() {
          return 0
        }
      },
      timeObjTaskNum: {
        type: Number,
        default() {
          return 0
        }
      },
      form: {
        type: Object,
        default() {
          return {
            platform_id: '', //商城id
            platform_play_id: '', // 任务类型id
            platform_play_type: '', //任务类型是啥 0=未知；1=普通垫付；2=预售垫付；3=叠词回访；4=竞品流量劫持；5=高级引流；6=特别垫付；7=开团垫付；8=直播垫付；
            entrance: '', //活动入口
            user_shop_id: '', //店铺id
            url: '', //商品链接
            title: '', //商品标题
            img: [], // // 宝贝示例图 商品示例图，最多3张
            search_price: '',//搜索展示价
            actual_price: '',//产品下单价
            entrance_remark: '', // 入口描述
            tkl: '',  // 淘口令
            entrance_pic: [], //步骤截图
            search_sort: '综合排序',  // 搜索排序方式  综合排序;销量排序;直通车
            search_remark: '', //搜索备注
            is_free: 1, //1-包邮 0-不包邮
            task_type: '',//搜索关键词任务类型
            search_keyword: [ { keyword: '', num: ''  }], // 搜索关键词列表
            buy_num: '', //拍下件数
            goods: [], // 附加商品列表  { url: '', img: '', price: '', num: '' }
            // 详情页关键字
            detail_keyword: '',
            // 货比
            huobi: '',
            // 浏览副宝贝
            liulan: '',
            // 浏览主宝贝
            main_liulan: [],
            // 支付方式
            pay_type: '',

            // 发布时间的对象
            time_obj: {},
            // 发布方式
            time_type: 1,
            // 任务发布时间
            time_val: '',
            // 任务发布间隔
            time_interval: 5,
            // 加赏佣金
            reward: '',
            // 商家审核
            task_pass: 0,

            // 开始时间
            startDate: new Date(),

            // 传给后台的开始时间
            get start_date() {
              let year = new Date(this.startDate).getFullYear()
              let month = new Date(this.startDate).getMonth() + 1
              let day = new Date(this.startDate).getDate() 
              return `${year}-${month}-${day}`
            },

            
            // 开始时刻
            startTime: -1,
            // 结束时刻
            endTime: -1,
            // 任务回收时间
            close_time: 0,
            // 截图 0 不截图 1 截图
            screenshot: 0,  

            screenshot_arrival: 0,
            
            // 特殊要求
            explain:'',
            // 特殊要求示例图
            explain_img: [],
            // 信誉要求
            xinyu: 1,
            // 开通花呗 0不需要 1需要
            credit_pay: 0,

            // 性别限制
            sex: 0,

            // 年龄限制
            age: 0,

            // 地区限制
            province_code: [],
            // 店铺申请限制
            shop_limit: 7,
            // 发布申请限制
            account_limit: 0,




            link: '',
            // 直播的时候进店类型
            zhiboType: 1,
            // 找到主播的方式
            findZhubo: 1,

            // 主播名字或者链接
            zhuboName: '',
            // 主播的二维码
            zhuboQRcode: '',

            // 步骤截图
            stepImgList: [],

            // 是否关注主播
            guanZhuzhubo: 1,
          }
        }
      }
    },
    data() {
      return {
        formData: {
          addmoney: '',
          shenghe: 1,
          payType: 1
        },
        rules: {
          title: [
            { required: true, message: '请输入商品标题', trigger: 'blur' },
          ],
        },
        shengheList: [
          { name: '需要', value: 1 },
          { name: '不需要', value: 2 },
        ]
      }
    },
    methods: {
      arraySpanMethod({ rowIndex, columnIndex }) {
        if(columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
            if (rowIndex % 4 === 0) {
              return {
                rowspan: 4,
                colspan: 1
              };
            } else {
              return {
                rowspan: 0,
                colspan: 3
              };
            }
        }
      }
    },
    computed: {
      tableData() {
        return [
          { 
            title: '本金',
            value: Math.formatFloat(this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney, 2),
            total: Math.formatFloat((this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney) * this.totalTaskNum, 2),
            number: this.totalTaskNum,
            price: this.BenJinMoney
          },
          { 
            title: '基础服务费',
            value: Math.formatFloat(this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney, 2),
            total: Math.formatFloat((this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney) * this.totalTaskNum, 2),
            number: this.totalTaskNum,
            price: this.servicePrice
          },
          { 
            title: '基础佣金',
            value: Math.formatFloat(this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney, 2),
            total: Math.formatFloat((this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney) * this.totalTaskNum, 2),
            number: this.totalTaskNum,
            price: this.BaseMoney
          },
          { 
            title: '增值服务费',
            value: Math.formatFloat(this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney, 2),
            total: Math.formatFloat((this.BenJinMoney + this.BaseMoney +  this.servicePrice + this.ZengZhiMoney) * this.totalTaskNum, 2),
            number: this.totalTaskNum,
            price: this.ZengZhiMoney
          }
        ]
      }
    }
  }
</script>

<style lang="scss">
.v_task_issue_dian_fu_step_three {
    .el-form {
    .s-task-issue-step-item {
      margin-top: 50px;
      .el-form-item {
        .el-form-item__label {
          font-weight: bold;
        }
      }
      .s-task_issue_dian_fu-item-active-num {
        .el-col {
          .el-form-item {
            .el-form-item__content {
              margin-left: 0!important;
            }
          }
        }

      }
    }
  }
}

</style>