<template>
  <div class="s_upload">
    <input type="file" @change="fileSelected()" :accept="uploadAccept" :id="'s-task-yu-sou-upload' + idNumber" style="display:none;">
  </div>
</template>

<script>
  import { apiUploadFile } from '@/utils/api'
  export default {
    name: 'TaskUpload',
    props: {
      // 很重要 一定要填
      idNumber: {
        type: Number,
        required: true,
        default() {
          return 0
        }
      },
      // 1 图片
      // 2 文件
      uploadType: {
        type: Number,
        default() {
          return 0
        }
      },
      uploadAccept: {
        type: String,
        default() {
          return ''
        }
      }
    },
    data() {
      return {        
        imgLimitSize: 1024 * 1024 * 5, //图片限制大小 5m
        fileLimitSize: 1024 * 1024 * 10 //文件限制大小
      }
    },
    methods: {
      // 监听input file 的变化进行上传
      async fileSelected() {
        if(!document.getElementById('s-task-yu-sou-upload' + this.idNumber).files.length) return

        let file = document.getElementById('s-task-yu-sou-upload'+ this.idNumber).files[0]
        console.log(file)

        // if(this.uploadAccept.indexOf(file.type) === -1) return this.$message({ message: '请上传图片', type: 'warning'});

        // formdata提交
        var formData = new FormData()
        formData.append('file', file)

        // 触发开始上传
        // this.$emit('emitStartUpload', file)

        let loading = this.$loading()

        // 开始上传
        const { success, data } = await apiUploadFile(formData).catch(() => {
          loading.close()
          this.$emit('emitFailUpload')
          return document.getElementById('s-task-yu-sou-upload'+ this.idNumber).value = null
        })
        loading.close()
        if(!success) {
          // 触发上传失败
          this.$emit('emitFailUpload')
          return document.getElementById('s-task-yu-sou-upload'+ this.idNumber).value = null
        } 
        // 提示
        this.$message.success(data.msg)
        // 上传成功触发出去
        this.$emit('emitUploadSuccess', data)
        // 清空
        document.getElementById('s-task-yu-sou-upload'+ this.idNumber).value = null
      },
    }
  }
</script>

<style lang="scss">
</style>