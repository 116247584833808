<template>
  <div class="s_task_issue_dian_fu_step_two_add_goods">
    <el-form :model="goodsItemForm" ref="goodsItemForm" class="demo-ruleForm" label-width="120px">
      <el-row :gutter="50">
        <!-- 商品链接 -->
        <el-col :span="12">
          <el-form-item label="商品链接：" class="s-task-issue-step-item-require" label-width="120px">
            <el-row :gutter="20">
              <el-col :span="16">
                  <el-input v-model="goodsItemForm.url" size="small"></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>


        <!-- 商品主图 -->
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="商品主图：" class="s-task-issue-step-item-require" label-width="120px">
              <div class="s-task-issue-step-upload-box g-flex-align-center g-flex-wrap">
                <!-- 图片 -->
                <ul class="s-task-issue-step-upload-img-list g-flex-align-center">
                  <li class="s-task-issue-step-upload-img-list-item" v-show="goodsItemForm.img">
                    <el-image fit="cover" style="width: 100px; height: 100px" :src="goodsItemForm.img" :preview-src-list="[goodsItemForm.img]"> </el-image>
                    <i class="iconfont iconcuo g-pointer" @click="deleteImgItem"></i>
                  </li>
                </ul>
                <!-- 上传按钮 -->
                <div class="s-task-issue-step-upload g-flex-column g-flex-align-center g-flex-justify-center g-pointer" @click="uploadClick()" v-show="!goodsItemForm.img">
                  <i class="iconfont iconshangchuantupian"></i>
                  <span>上传图片</span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 下单价 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="下单价：" class="s-task-issue-step-item-require" label-width="120px">
              <el-row :gutter="20">
                <el-col :span="4" class="g-flex-align-center">
                  <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="goodsItemForm.price" size="small"></el-input>
                  <span class="g-padleft10">元</span>
                </el-col>
                <el-col :span="18" class="g-flex-align-center">
                  <span class="g-padleft10 g-grey">如果不同等级买家看到的商品价格不同，则取最大值</span>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 拍下每单件数 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="拍下每单件数：" class="s-task-issue-step-item-require" label-width="120px">
              <el-row :gutter="20">
                <el-col :span="4" class="g-flex-align-center">
                  <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="goodsItemForm.num" size="small"></el-input>
                  <span class="g-padleft10">件</span>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
    </el-form>

    <task-upload ref="taskupload" :idNumber="idNumber" :uploadAccept="uploadAccept" @emitFailUpload="emitFailUpload" @emitUploadSuccess="emitUploadSuccess"/>
  </div>
</template>

<script>
  import TaskUpload from './TaskUpload.vue'
  export default {
    components: { TaskUpload },
    props: {
      idNumber: {
        type: Number,
        default() {
          return 1
        }
      },
      goodsItemForm: {
        type: Object,
        default() {
          return  { url: '', img: '', price: '', num: '' }
        }
      }
    },
    data() {
      return {
        uploadAccept: 'image/jpeg,image/jpg,image/png,image/PNG,image/JPG,image/JPEG', //上传的限制类型
        rules: {
          url: [
            { required: true, message: '请输入商品地址', trigger: 'blur' },
          ],
        }
      }
    },
    methods: {
      uploadClick() {
        this.$nextTick(() => {
          // this.$refs.taskupload.$el.firstChild.click()
          console.log( document.getElementById('s-task-yu-sou-upload'+ this.idNumber))
          document.getElementById('s-task-yu-sou-upload'+ this.idNumber).click()
        })
      },
      emitFailUpload() {
        this.$message.error('上传失败,请重试')
      },
      emitUploadSuccess(obj) {
        console.log('success',obj)
        console.log('xxxx',this.goodsItemForm)

        this.goodsItemForm.img = obj.url
      },
      deleteImgItem() {
        this.goodsItemForm.img = ''
      }
    }
  }
</script>

<style lang="scss">
.s_task_issue_dian_fu_step_two_add_goods {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px dashed #ccc;
}
</style>