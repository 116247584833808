<template>
 <div class="v_task_issue_dian_fu_step_fourth">
   <div class="v-task-issue-dian-fu-step-four-box g-flex-column g-flex-align-center">
     <i class="iconfont iconchenggong"></i>
     <h3 v-show="taskSucStaus === 10 || taskSucStaus === 20">任务提交成功!</h3>
     <h3 v-show="taskSucStaus === 30">任务发布成功!</h3>
     <span class="v-task-issue-dian-fu-step-four-tips" v-show="taskSucStaus === 20">任务提交成功，即将发布，请耐心等待后台人员审核</span>
     <el-link type="danger" v-show="taskSucStaus === 10" @click="$router.push({ path: '/moneystore/moneystorerecharge'})">余额不足,前去充值</el-link>
     <span class="v-task-issue-dian-fu-step-four-tips" v-show="taskSucStaus === 10">充值完成后，请到任务列表进行支付该任务费用</span>
     <el-link type="primary" v-show="taskSucStaus === 20" @click="$router.push({ path:'/issuetask2/taskstoreyusoulist' })">查看审核状态</el-link>
     <el-link type="success" v-show="taskSucStaus === 30" @click="$router.push({ path:'/issuetask2/taskstoreyusoulist' })">查看任务列表</el-link>
   </div>
 </div>
</template>

<script>
export default {
  props: {
    //任务发布成功状态 10 未支付（要充值） 20 等待后台审核 30 发布成功
    taskSucStaus: {
      type: Number,
      default() {
        return 10
      }
    }

  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.v_task_issue_dian_fu_step_fourth {
  margin-top: 50px;
  background: $white;
  padding: 80px;
  border-radius: 8px;
  .iconfont {
    margin-top: 20px;
    font-size: 100px;
    color: #67C23A;
  }
  h3 {
    margin-top: 80px;
    color: #000000;
    font-size: 30px;
  }
  .v-task-issue-dian-fu-step-four-tips {
    color: $grey;
    font-size: 14px;
  }

  .el-link {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
</style>